(function ($, app) {
  var self = null;
  var controller = function ($element) {
    self = this;
    self.$element = $element.find("select");
    self.generalFunctions = app.util("generalFunctions");
    self.init();
  };

  controller.prototype.init = function () {
    var self = this;
    self.setInitValue();
    self.$element.on("change", function () { self.onFilterChange(); });
  };

  controller.prototype.setInitValue = function () {
    var self = this;
    var key = self.$element.data("key");
    var value = self.generalFunctions.getUrlParameter(key);
    self.$element.selectpicker();
    if (value && value.length > 0) {
      self.$element.selectpicker("val", value);
    }
  };

  controller.prototype.onFilterChange = function () {
    var self = this;
    var key = self.$element.data("key");
    var value = self.$element.val();
    var url = location.href;
    url = self.generalFunctions.updateURLParam(url, key, value);
    location.href = url;
  };

  app.controller("DropDownFilter", controller);
})(jQuery, app);
