(function ($, app) {
  var self = null;
  var ajaxHandler = null;
  var generalFunctions = null;

  var controller = function ($element) {
    ajaxHandler = app.util("AjaxHandlers");
    generalFunctions = app.util("generalFunctions");
    self = this;
    self.$element = $element;
    self.init();
  };

  controller.prototype.init = function () {
    var $firstTab = $(".tab-option.active .tab-control");
    self.reloadTabs();
    self.loadMoreCards();
    self.loadFirstTab();
  };

  controller.prototype.loadFirstTab = function() {
    var urlTab = generalFunctions.getUrlParameter("tab"),
        $currentActive = $(".tab-option.active .tab-control"),
        $tabs = $(".tab-control");

    if(typeof urlTab === "undefined") {
      self.loadTabCards($currentActive);
    }
    else {
      var $tabToActive = $($tabs[parseInt(urlTab)-1]);
      if($tabToActive.length > 0) {
        $tabToActive.click();
        self.loadTabCards($tabToActive);
      }
      else {
        self.loadTabCards($currentActive);
      }
    }
  };

  controller.prototype.reloadTabs = function() {
    $(".tab-control").click(function() {
      self.loadTabCards($(this));
    });
  };

  controller.prototype.loadTabCards = function($tab) {
    var pristine = $tab.attr("data-pristine");
    if(pristine == 1){
      $tab.attr("data-pristine", 0);
      var id          = $tab.attr("data-paneid"),
          $container  = $("#"+id+" .cards-container"),
          $loadButton = $container.find(".load-more"),
          term        = $loadButton.attr("data-term"),
          all         = $loadButton.attr("data-all"),
          page        = $loadButton.attr("data-page"),
          type        = $loadButton.attr("data-type"),
          view        = $loadButton.attr("data-view");
          sort        = $loadButton.attr("data-sort");

      ajaxHandler.retrieveCards($container, $loadButton, term, all, page, type, view, sort);
    }
  }

  controller.prototype.loadMoreCards = function() {
    $(".wp-cardsintabs .load-more").click(function() {
      var $container = $(this).parent(".cards-container"),
          term       = $(this).attr("data-term"),
          all        = $(this).attr("data-all"),
          page       = $(this).attr("data-page"),
          type       = $(this).attr("data-type"),
          view       = $(this).attr("data-view");
          sort       = $(this).attr("data-sort");

      ajaxHandler.retrieveCards($container, $(this), term, all, page, type, view, sort);
    });
  }

  app.controller("WpCardsInTabs", controller);
})(jQuery, app);
