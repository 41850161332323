(function ($, app) {
  var controller = function ($element) {
    self = this;
    self.FORMAT_FILTER = "YYYYMM"; // Date format in query string parameter.
    self.FORMAT_CAPTION = "MMM, YYYY"; // Date format for the button's caption.
    self.$element = $element.find(".js-archive-filter");
    self.generalFunctions = app.util("generalFunctions");
    self.init();
  };
  controller.prototype.init = function () {
    var self = this;
    self.setInitValue();
    self.initDatePicker();
  };

  controller.prototype.setInitValue = function () {
    var self = this;
    var key = self.$element.data("key");
    var value = self.generalFunctions.getUrlParameter(key);
    if (value && value.length > 0) {
      var momentDate = moment(value, self.FORMAT_FILTER);
      if (momentDate.isValid()) {
        var caption = momentDate.format(self.FORMAT_CAPTION);
        self.$element.text(caption);
      }
    }
  };

  controller.prototype.initDatePicker = function () {
    var self = this;
    self.$element.datepicker({
      format: "mm-yyyy",
      startView: "months",
      minViewMode: "months"
    }).on("changeMonth", function(e) {
      self.onMonthClicked(e);
    });
    self.closeEventDatePickerIOS();
  };

  controller.prototype.onMonthClicked = function (e) {
    var self = this;
    var date = e.date;
    var value = moment(date).format(self.FORMAT_FILTER);
    var caption = moment(date).format(self.FORMAT_CAPTION);
    var key = self.$element.data("key");
    self.$element.text(caption);
    var url = location.href;
    url = self.generalFunctions.updateURLParam(url, key, value);
    location.href = url;
  };

  //Fixing close event DatePicker only in IOS
  controller.prototype.closeEventDatePickerIOS = function(){
    var is_ios = /(iPad|iPhone|iPod)/g.test( navigator.userAgent );
    if(is_ios){
      $("body").css("cursor", "pointer");
    }
  };
  app.controller("ArchiveFilter", controller);
})(jQuery, app);
