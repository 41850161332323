(function ($, app) {
  var self = null;

  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.init();
  };
  controller.prototype.init = function () {
    self.listeners();
  };

  controller.prototype.listeners = function () {
    $(".wp_supportmaterials .title").on("click", function(){
      if($(this).hasClass("active")){
        $(".wp_supportmaterials .text").slideUp();
        $(this).removeClass("active");
      }else{
        $(".wp_supportmaterials .text").slideUp();
        $(".wp_supportmaterials .title").removeClass("active");
        $(this).addClass("active");
        $(".active + .text").slideDown();
      }
    });
  };
  app.controller("WpSupportMaterials", controller);
})(jQuery, app);
