(function ($, app) {
  var self = null;
  var clampController = function ($element) {
    self = this;

    self.$defaultConfig = {
      ellipsis	: "... ",
  		wrap : "word",
  		fallbackToLetter: true,
  		watch	: window,
  		tolerance	: 0,
      callback: self.clampCallback
    };
    self.$element = $element;
    self.$button = $element.find(".see-more");
    self.init();
  };

  clampController.prototype.init = function () {
    // Init the ellipsis for all the info boxes
    self.$element.find('.info').dotdotdot(self.$defaultConfig);

    self.$button.each(function () {
      $(this).on("click", function (event) {
        event.preventDefault();
        self.toggleText(event, $(this));
      });
    });
  };

  // Toggle the text to be displayed/hidden
  clampController.prototype.toggleText = function (e, $button) {
    var self = this;
    var $info = $button.parent().find(".info");

    if($info.hasClass("more")) {
      $info.removeClass("more");
      $info.trigger("destroy");
      $button[0].innerText = "See Less";
    }
    else {
      $info.addClass("more");
      $info.dotdotdot(self.$defaultConfig);
      $button[0].innerText = "See More";
    }

    $button.blur();
  };

  // If the text is nos truncated, hide the "See More" button
  clampController.prototype.clampCallback = function(isTruncated) {
    if(!isTruncated) {
      self.$button.hide();
    }
  };

  app.controller("Clamp", clampController);
})(jQuery, app);
