(function ($, app) {
  var generalFunctions = function () {};

  generalFunctions.prototype.getUrlParameter = function(sParam){
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++)
    {
      var sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam)
      {
          return sParameterName[1];
      }
    }
  };

  generalFunctions.prototype.removeURLParam = function(key){
    var href = location.href;
    var parameters="?";
    // if "?" is found means that there is one or more parameters
    if(href.indexOf("?") > -1){
      var currentParameters = href.split("?")[1].split("&");
      var i = 0;
      for(;i < currentParameters.length ; i++){
        var parameterAndValue = currentParameters[i];

        if(parameterAndValue.split("=")[0] !== key && parameterAndValue !== "="){
          parameters += parameterAndValue + "&";
        }
      }
      if(parameters[parameters.length-1] === "&"){
        parameters = parameters.substr(0, parameters.length-1);
      }
      history.pushState("","",parameters);
    }
  };

  generalFunctions.prototype.updateURLParam = function (url, key, value) {
    var retUrl = url.split("#")[0];
    var hash = url.split("#")[1];
    hash = (hash) ? "#" + hash : "";
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = retUrl.indexOf('?') !== -1 ? "&" : "?";
    if (url.match(re)) {
      retUrl = retUrl.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      retUrl = retUrl + separator + key + "=" + value;
    }
    return retUrl + hash;
  };

  generalFunctions.prototype.wrapHashtag = function(text) {
    hashtag_regexp = /#([a-zA-Z0-9]+)/g;
    return text.replace(
        hashtag_regexp,
        '<span class="hashtag">#$1</span>'
    );
  }

  // Open the "Request Quote" modal
  generalFunctions.prototype.showRequestQuoteModal = function (element) {
    var safariTitle = $(element).attr("data-name");

    if(typeof ga !== 'undefined') {
      ga('send', 'event', 'safari quote', 'request a quote of safari', safariTitle);
    }

    $("#edit-submitted-safari-of-interest").val(safariTitle);
    $('#request-quote-modal').modal('show');
  };

  app.util("generalFunctions", {
    getUrlParameter: generalFunctions.prototype.getUrlParameter,
    removeURLParam: generalFunctions.prototype.removeURLParam,
    updateURLParam: generalFunctions.prototype.updateURLParam,
    wrapHashtag: generalFunctions.prototype.wrapHashtag,
    showRequestQuoteModal: generalFunctions.prototype.showRequestQuoteModal
  });

})(jQuery, app);
