(function ($, app) {
  var self = null;
  var AjaxHandlers;

  var controller = function ($element) {
    AjaxHandlers = app.util("AjaxHandlers");

    self = this;
    self.$element = $element;
    self.countries = [
      {code: "UG", name: "Uganda"},
      {code: "EG", name: "Egypt"},
      {code: "TZ", name: "Tanzania"},
      {code: "ZM", name: "Zambia"},
      {code: "NA", name: "Namibia"},
      {code: "ZA", name: "South Africa"},
      {code: "ZW", name: "Zimbabwe"},
      {code: "BW", name: "Botswana"},
      {code: "KE", name: "Kenya"},
      {code: "RW", name: "Rwanda"},
      {code: "MZ", name: "Mozambique"},
      {code: "RE", name: "Mauritius"},
      {code: "MG", name: "Madagascar"},
      {code: "SE", name: "Seychelles"}
    ];

    self.CLASS_ENABLE = "enabled";
    self.CLASS_SELECTED = "selected";
    self.CLASS_SHOW = "show";
    self.NAMESPACE = "atimap"; //namespace for global events.

    this.init();
  };

  controller.prototype.init = function () {
    this.listeners();
    self.initializeMap();
    self.addDataCode();
  };

  controller.prototype.listeners = function() {
    $(".countries ul li").on('click', function() {
      $(".countries ul li").removeClass("selected");
      self.loadDestination($(this).attr("data-country"));
      self.deselectRegions();
      id = $(this).attr("data-code");
      self.addClassToPath($("#"+id), self.CLASS_SELECTED);
      $(this).addClass("selected");
    });

    $(".country_info_container img").on('click', function() {
      $(".country_info_container").addClass("hide");
    });
  };

  controller.prototype.loadDestination = function(id) {
    AjaxHandlers.retrieveCountry(id);
    $(".country_info a").attr("href", "/content/page-country?id="+id);
  };

  //Adding data code to country list in order to associate with the map
  controller.prototype.addDataCode = function() {
    self.countries.forEach(function(country) {
      $(".countries li").each(function(index) {
        if($(this).text().toLowerCase()==country.name.toLowerCase()) {
          $(this).attr('data-code', country.code);
          return;
        }
      });
    });
  }

  //Map Functionality
  controller.prototype.initializeMap = function() {
    self.pullCountries().done(function() {
      self.processPaths(self.countries);
      self.addCountryLabelEvent();
      self.registerEvents();
    });
  };

  // Gets the countries list, from server or data attribute.
  controller.prototype.pullCountries = function() {
    var self = this;
    var deferred = $.Deferred();
    deferred.resolve(self.countries);
    return deferred;
  };

  // Loop through the countries list, and matches the country code with the path
  // id which is the 2-digit iso code. Then it adds the enabled class to indicate
  // that the region is available and hover events should be listened.
  controller.prototype.processPaths = function(countries) {
    countries.forEach(function (country)
    {
      var $path = self.$element.find("#" + country.code);
      // addClass will not work here.
      if ($path.length > 0) {
        self.addClassToPath($path, self.CLASS_ENABLE);
        $path.data("country", country);
        $path.on("click", function (e) { self.onPathClicked(e, $(this)); });
      }
    });
  };

  controller.prototype.registerEvents = function() {
    var self = this;
    $(window).on("resize."+self.NAMESPACE, function() {
      $selectedPath = $("path."+self.CLASS_SELECTED);
    });
  };


   controller.prototype.onPathClicked = function(e, $path) {
    var self = this;
    var country = $path.data("country");
    $('li[data-code="'+country.code+'"]').click();
    $(".country_info_container").addClass("hide");
    self.deselectRegions();
    self.addClassToPath($path, self.CLASS_SELECTED);
  };

  controller.prototype.deselectRegions = function() {
    $enabledPaths = self.$element.find("path.enabled, g.enabled");
    $enabledPaths.each(function() {
      var $currentPath = $(this);
      self.removeClassFromPath($currentPath, self.CLASS_SELECTED);
    });
  };

  controller.prototype.addClassToPath = function($path, classString) {
    var currentClass = $path.attr("class");
    $path.attr("class", currentClass + " " + classString);
  };

  controller.prototype.removeClassFromPath = function($path, classString) {
    var currentClass = $path.attr("class");
    newClass = currentClass.replace(" " + classString, "");
    $path.attr("class", newClass);
  };

  // When the user clicks the name of the country, the country area is clicked programmatically
  controller.prototype.addCountryLabelEvent = function() {
    $(".js-country-label").on("click", function(e) {
      var countryName = $(this).attr("data-country");
      var $path = self.$element.find("#" + countryName);

      if ($path.length > 0) {
        $($path).click();
      }
    });
  };

  app.controller("WpCardDestination", controller);
})(jQuery, app);
