/**
 * @file
 * Custom scripts for theme.
 */
var app = (function ($) {
  "use strict";
  var controllers = {};
  var components = {};
  var utils = {};

  $(document).ready(function () {
     init();
  });

  function init () {
    initControllers();
    initComponents();
  }

  function initControllers() {
    $("[data-controller]").each(function () {
      var $element = $(this);
      var controllerName = $element.data("controller");
      var controller = controllers[controllerName];
      if (controller) {
        new controller($element);
      } else {
        throw new Error("Undefined controller: \"" + controllerName + "\"");
      }
    });
  }

  function initComponents() {
    $.each(components, function( name, object ) {
      new object();
    });
  }

  function controller(name, object) {
    if (!object) {
      return getHandler(name, "Controller");
    }
    registerHandler(name, object, "Controller");
  }

  function util(name, object) {
    if (!object) {
      return getHandler(name, "Util");
    }
    registerHandler(name, object, "Util");
  }

  function component(name, object) {
    if (!object) {
      return getHandler(name, "Component");
    }
    registerHandler(name, object, "Component");
  }

  function registerHandler(name, object, type) {
    var container;
    switch(type){
      case "Controller":
        container = controllers;
        break;
      case "Util":
        container = utils;
        break;
      case "Component":
        container = components;
        break;
      default:
        throw new Error("The handler type [" + type + "] does not exist.");
        break;
    }
    var handler = container[name];
    if (handler) {
      throw new Error(type+": \"" + name + "\" is already declared!");
    }
    container[name] = object;
  }

  function getHandler(name, type) {
    var container;
    switch(type){
      case "Controller":
        container = controllers;
        break;
      case "Util":
        container = utils;
        break;
      case "Component":
        container = components;
        break;
      default:
        throw new Error("The handler type [" + type + "] does not exist.");
        break;
    }
    var handler = container[name];
    if (!handler) {
      throw new Error("Undefined "+type+": \"" + name + "\"");
    }
    return handler;
  }

  return {
    controller: controller,
    util: util,
    component: component
  };

})(jQuery);
