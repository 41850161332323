(function ($, app) {
  var self = null;
  var FormFunctions;

  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.$form = $("#newsletter-modal form");
    self.$submit = self.$form.find("input[type='submit']");
    self.home = self.$element.attr("data-home");

    FormFunctions = app.util("formFunctions");

    self.init();
  };

  controller.prototype.init = function () {
    //self.formSubmit();
    self.checkIsHomeFirstTime();

    FormFunctions.handleHiddenFields();
    FormFunctions.addClassBySelector('.newsletter .form-actions .webform-submit','button-2 block');
  };

  controller.prototype.formSubmit = function() {
    self.$submit.click(function(e){
      var $email = self.$form.find(".email-first"),
          $confirmedEmail = self.$form.find(".email-to-confirm");

      if($email.val() !== $confirmedEmail.val()){
        $confirmedEmail.get(0).setCustomValidity('The two email addresses must match.');
      }else{
        $confirmedEmail.get(0).setCustomValidity('');
      }

      self.$form.submit();

    });

    self.$form.submit(function(e){
      if(!e.target.checkValidity()){
        e.preventDefault()
      }
    });
  };

  controller.prototype.checkIsHomeFirstTime = function() {
    var cookie;
    if(self.home === "1"){
      cookie = $.cookie("home-visited");
      if(cookie === null || typeof cookie === "undefined"){
        $.cookie('home-visited', 'yes', { expires: 3 });
        self.$element.modal('show');
      }
    }
  };

  app.controller("Newsletter", controller);
})(jQuery, app);