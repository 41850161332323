(function ($, app) {
  var self = null;
  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.generalFunctions = app.util("generalFunctions");
    self.$searchTabs = $(".search-tabs").first();
    self.$searchTabsMobile = $(".tabs-mobile").first();
    self.$searchField = $(".js-search-field");
    self.init();
  };
  
  controller.prototype.init = function () {
    var self = this;
    var searchString = self.generalFunctions.getUrlParameter("search");

    if (searchString && searchString.trim().length > 0) {
      var decodedString = decodeURIComponent(searchString);
      decodedString = decodedString.replace(/\+/g, ' ');
      self.$searchField.val(decodedString);
    }

    self.$searchTabs.find(".tab-control").each(function () {
      $(this).on("click", function (e) {
        self.onSideLinkClicked(e, $(this));
      });
    });

    self.$searchTabsMobile.find(".tab-option").each(function () {
      $(this).on("click", function (e) {
        self.onSideLinkClicked(e, $(this));
      });
    });
  };

  controller.prototype.onSideLinkClicked = function (e, $a) {
    var self = this;
    var href = $a.attr("href");
    var searchString = self.$searchField.val();
    if (searchString && searchString.trim().length > 0) {
      href += "?search=" + encodeURIComponent(searchString);
    }
    $a.attr("href", href);
  };


  app.controller("Search", controller);
})(jQuery, app);
