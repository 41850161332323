(function ($, app) {
  var self = null;
  var GeneralFunctions;

  var controller = function ($element) {
    self = this;
    self.$element = $element;
    GeneralFunctions = app.util("generalFunctions");
    self.init();
  };

  controller.prototype.init = function () {
    self.wrapHashtags();
  };

  controller.prototype.wrapHashtags = function () {
    var $texts = $(".post .text");
    $.each($texts, function(indx, obj){
      var result = GeneralFunctions.wrapHashtag($(obj).text());
      $(obj).html(result);
    });
  };

  app.controller("WpSocial", controller);
})(jQuery, app);
