(function ($, app) {
  var self = null;
  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.$url = $(".video_data").data("video");
    self.init();

  };
  controller.prototype.init = function () {
    self.listeners();
  };

  controller.prototype.listeners = function () {
    //Show Video
    $(".wp_video .video_data").on("click", function(){
      $('#video-modal').modal('show');
      $('#video-modal .video').attr('src', self.$url);
    });

    //hide video
    $('#video-modal').on('hidden.bs.modal', function () {
      $('#video-modal .video').attr('src', '');
    });
  };

  app.controller("Video", controller);
})(jQuery, app);
