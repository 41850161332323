(function ($, app) {
  var self = null;
  var ajaxHandler;

  var controller = function ($element) {
    ajaxHandler = app.util("AjaxHandlers");
    self = this;
    self.$element = $element;
    self.$loadMore = $(".wp-cardcontainer .load-more");
    self.init();
  };

  controller.prototype.init = function () {
    self.loadMoreCards();
    self.$loadMore.click();
  };

  controller.prototype.loadMoreCards = function(){
    self.$loadMore.click(function(){
      var $container = $(this).parent(".cards-container"),
          term       = $(this).attr("data-term"),
          all        = $(this).attr("data-all"),
          page       = $(this).attr("data-page");
      ajaxHandler.retrieveCards($container, $(this), term, all, page, 1, "special_card");
    });
  }

  app.controller("WpCardContainer", controller);
})(jQuery, app);