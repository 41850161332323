(function ($, app) {
  var self = null;
  var AjaxHandlers;
  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.$map = $element.find(".vmap");
    self.$tooltip = $element.find(".map-tooltip");
    self.$tooltipContent = $element.find(".tooltip-content");
    self.$regionDetails = $element.find(".region-details");
    self.tooltipTemplate = Handlebars.compile($element.find(".tooltip-template").html());
    self.detailsTemplate = Handlebars.compile($element.find(".details-template").html());
    self.detailsTemplateMobile = Handlebars.compile($element.find(".details-template-mobile").html());
    // Every path/country that matches the data, will be tagged with this class
    self.CLASS_ENABLE = "enabled";
    self.CLASS_SELECTED = "selected";
    self.CLASS_SHOW = "show";
    self.NAMESPACE = "atimap"; //namespace for global events.
    self.SVG_WIDTH = 778;
    self.SVG_HEIGHT = 790;
    self.ANIMATION_TIME = 300; // Milliseconds. This should match the SASS animationTime variable.

    // TODO: Bind this data with server info. The country code matches each svg path.

    self.countries = [
      {code: "UG", name: "Uganda"},
      {code: "EG", name: "Egypt"},
      {code: "TZ", name: "Tanzania"},
      {code: "ZM", name: "Zambia"},
      {code: "NA", name: "Namibia"},
      {code: "ZA", name: "South Africa"},
      {code: "ZW", name: "Zimbabwe"},
      {code: "BW", name: "Botswana"},
      {code: "KE", name: "Kenya"},
      {code: "RW", name: "Rwanda"},
      {code: "MZ", name: "Mozambique"},
      {code: "RE", name: "Mauritius"},
      {code: "MG", name: "Madagascar"},
      {code: "SE", name: "Seychelles"}
    ];

    AjaxHandlers = app.util("AjaxHandlers");
    self.init();
  };
  controller.prototype.init = function () {
    var self = this;
    AjaxHandlers.retrieveAllCountries(self);
    self.pullCountries().done(function (countries) {
      self.processPaths(countries);
      self.addCountryLabelEvent();
      self.registerEvents();
    });
  };

  // Loop through the countries list, and matches the country code with the path
  // id which is the 2-digit iso code. Then it adds the enabled class to indicate
  // that the region is available and hover events should be listened.
  controller.prototype.processPaths = function (countries) {
    countries.forEach(function (country)
    {
      var $path = self.$element.find("#" + country.code);
      // addClass will not work here.
      if ($path.length > 0) {
        self.addClassToPath($path, self.CLASS_ENABLE);
        $path.data("country", country);
        $path.on("click", function (e) { self.onPathClicked(e, $(this)); });
      }
    });
  };

  // When the user clicks the name of the country, the country area is clicked programmatically
  controller.prototype.addCountryLabelEvent = function() {
    $(".js-country-label").on("click", function(e) {
      var countryName = $(this).attr("data-country");
      var $path = self.$element.find("#" + countryName);

      if ($path.length > 0) {
        $($path).click();
      }
    });
  };

  controller.prototype.registerEvents = function () {
    var self = this;
    $(window).on("resize."+self.NAMESPACE, function () {
      $selectedPath = $("path."+self.CLASS_SELECTED);
      if(!$selectedPath[0]){
        $selectedPath = $("g."+self.CLASS_SELECTED);
      }
      if ($selectedPath.length > 0) {
        self.positionToolTip($selectedPath);
      }
    });
  };

  // Configures the tooltip for each path using the tooltipster library.
  controller.prototype.showTooltip = function ($path, country) {
    var self = this;
    setTimeout(function () {
      self.$tooltipContent.html(self.tooltipTemplate(country));
      self.positionToolTip($path);
      self.$tooltip.addClass(self.CLASS_SHOW);
    }, self.ANIMATION_TIME);
  };

  controller.prototype.hideTooltip = function () {
    var self = this;
    self.$tooltip.removeClass(self.CLASS_SHOW);
  };

  controller.prototype.showDetails = function ($path, country) {
    var self = this;
    setTimeout(function () {
      self.$regionDetails.html(self.detailsTemplate(country));
      self.$regionDetails.addClass(self.CLASS_SHOW);
    }, self.ANIMATION_TIME);
  };

  controller.prototype.hideDetails = function ($path, country) {
    var self = this;
    self.$regionDetails.removeClass(self.CLASS_SHOW);
  };

  // Gets the countries list, from server or data attribute.
  controller.prototype.pullCountries = function () {
    var self = this;
    var deferred = $.Deferred();
    deferred.resolve(self.countries);
    return deferred;
  };

  controller.prototype.positionToolTip = function ($path) {
    var self = this;
    dims = self.getPathDims($path);
    offset = $path.offset();
    offset.top = (offset.top + (dims.height / 2)) - self.$tooltipContent.height() / 2;
    offset.left = (offset.left + (dims.width / 2));
    offset.height = 0;
    self.$tooltip.offset(offset);
  };

  // This function calculates the region dimentions, since these are
  // SVGs paths, I use the rafael.js library to get the dimentions. However,
  // an aditional calculation is added, to get the real size of the paths,
  // by the given vieport size. Since the map is responsive.
  controller.prototype.getPathDims = function ($path) {
    var self = this;
    dims = Raphael.pathBBox($path.attr("d")); // Original dimentions.
    curSVGWidth = self.$map.width();
    curSVGHeight = self.$map.height();
    width = (dims.width * curSVGHeight) / self.SVG_WIDTH;
    height = (dims.height * curSVGHeight) / self.SVG_HEIGHT;
    return {width: width, height: height}; // Responsive dimentions.
  };

  controller.prototype.onPathClicked = function (e, $path) {
    var self = this;
    var country = $path.data("country");
    self.hideTooltip();
    self.deselectRegions();
    self.addClassToPath($path, self.CLASS_SELECTED);
    self.hideTooltip();
    self.showTooltip($path, country);
    self.hideDetails();
    self.showDetails($path, country);
  };

  controller.prototype.deselectRegions = function () {
    $enabledPaths = self.$element.find("path.enabled, g.enabled");
    $enabledPaths.each(function () {
      var $currentPath = $(this);
      self.removeClassFromPath($currentPath, self.CLASS_SELECTED);
    });
  };

  controller.prototype.addClassToPath = function ($path, classString) {
    var currentClass = $path.attr("class");
    $path.attr("class", currentClass + " " + classString);
  };

  controller.prototype.removeClassFromPath = function ($path, classString) {
    var currentClass = $path.attr("class");
    newClass = currentClass.replace(" " + classString, "");
    $path.attr("class", newClass);
  };

  controller.prototype.pathHasClass = function ($path, classString) {
    var currentClass = $path.attr("class");
    return (currentClass.indexOf(classString) > -1);
  };

  app.controller("BlockAtiMap", controller);
})(jQuery, app);
