(function ($, app) {
  var formFunctions = function () {};

  formFunctions.prototype.validateEmailAddress = function (email) {
    var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        valid   = true;
    if(!pattern.test(email)){
      valid = false;
    }
    return valid;
  };

  formFunctions.prototype.validatePhoneNumber = function (phone) {
    var pattern = new RegExp('^[0-9]+$'),
        valid   = true;
    if(!pattern.test(phone)){
      valid = false;
    }
    return valid;
  };

  formFunctions.prototype.validateTextField = function($el){
    return $el.val().trim() === "" ? false : true;
  };

  formFunctions.prototype.confirmEmail = function($email, $confirmation){
    return $email.val() === $confirmation.val();
  };

  formFunctions.prototype.addErrorClass = function(valid, $el) {
    if(!valid){
      $el.val('');
      if(!$el.hasClass('error')){
        $el.addClass('error');
      }
    }else{
      $el.removeClass('error');
    }
  };

  formFunctions.prototype.validForm = function($elements){
    var validForm = true;

    $elements.each(function(){
      if($(this).val() === ""){
        validForm = false;
        $(this).trigger("change");
      }
    });

    return validForm;
  };

  formFunctions.prototype.addClassBySelector = function($selector, $class){

    var element = $($selector);

    if(element.length){
      element.addClass($class);
    }

  };

  formFunctions.prototype.handleHiddenFields = function() {

    //WebForms class prefix
    var formItemSubmittedClass = '.form-item-submitted-';

    // Binds only to radio input tags with data-target attribute
    $('input[data-target][type=radio]').on("change", function(e){

      //Get the current element
      var selected_item = $('input[name="'+$(this).prop('name')+'"]:checked'),
      target = selected_item.attr("data-target"),
      selected_value = selected_item.val();

      //gets the target element
      var targetElement = $(formItemSubmittedClass + target + ' input[value="'+ selected_value +'"]');

      //updates the target element
      $(formItemSubmittedClass + target).prop("checked", false);
      targetElement.prop("checked", true);

    });


    // Binds only to checkbox input tags with data-target attribute
    $('input[data-target][type=checkbox]').on("change", function(e){

      //Get the current element
      var selected_item = $('input[name="'+$(this).prop('name')+'"]'),
      target = selected_item.attr("data-target");
      optionToCheck = null;

      // Is checked
      if($('input[name="'+$(this).prop('name')+'"]').is(':checked')){
        optionToCheck = 'YES';
      }else{
        optionToCheck = 'NO';
      }

      //Checked the selected item
      $(formItemSubmittedClass + target).prop("checked", false);
      $(formItemSubmittedClass + target + ' input[value="'+optionToCheck+'"]').prop("checked", true);

    });

  };


  app.util("formFunctions", {
    validateEmailAddress: formFunctions.prototype.validateEmailAddress,
    validatePhoneNumber: formFunctions.prototype.validatePhoneNumber,
    addErrorClass: formFunctions.prototype.addErrorClass,
    validForm: formFunctions.prototype.validForm,
    confirmEmail: formFunctions.prototype.confirmEmail,
    validateTextField: formFunctions.prototype.validateTextField,
    handleHiddenFields: formFunctions.prototype.handleHiddenFields,
    addClassBySelector: formFunctions.prototype.addClassBySelector
  });

})(jQuery, app);
