(function ($, app) {
  var self = null;
  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.init();
  };
  controller.prototype.init = function () {
    $(".hero_carousel_container").slick({
      dots: true,
      infinite: true,
      slidesToShow: 1,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false
    });
  };

  app.controller("WpHeroCarousel", controller);
})(jQuery, app);
