(function ($, app) {
  var self = null;
  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.init();
  };
  controller.prototype.init = function () {
    $(document).ajaxComplete(function () {
      $(".selectpicker").selectpicker("render");
    });
  };
  app.controller("AgentRegister", controller);
})(jQuery, app);
