(function ($, app) {
  var controller = function ($element) {
    var self = this;
    self.$element = $element.find("a");
    self.init();
  };
  controller.prototype.init = function () {
    var self = this;
    self.$element.on("click", function (e) { self.onClicked(e); });
  };
  controller.prototype.onClicked = function (e) {
    var self = this;
    var url = location.href;
    e.preventDefault();
    url = url.split("?")[0];
    if (url && url.length > 0) {
      location.href = url;
    }
  };

  app.controller("ClearFilters", controller);
})(jQuery, app);
