(function ($, app) {
  var self = null;

  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.init();
  };
  controller.prototype.init = function () {
    self.listeners();
  };

  controller.prototype.listeners = function () {
    $(".wp_webinars .video_info .hover_video_overlay, .wp_webinars .video_info .hover_play_bottom").on("click", function(){
      self.showVideo(this);
    });
    $(".wp_webinars .video_ovelay").on("click", function(){
      self.hideVideo();
    });
    $('.textinput').keyup(function() {
      self.filterVideos(this);
    });
  };

  controller.prototype.filterVideos = function (obj) {
    var $el = $(obj).val().toLowerCase();
    var foundResults=false;
    if(obj.value == ''){
    $('.video_info').fadeIn(450);
    }else {
      $('.video_info').each(function(){
        var txt = $(this).attr('data-filtro').toLowerCase();
        var match = txt.indexOf($el);
        if (match >= 0) {
          $(this).fadeIn(450);
          foundResults=true;
        }else{
          $(this).fadeOut(250);
        }
      });
      if(!foundResults){
        $(".view-empty").removeClass("hidden");
      }else{
        $(".view-empty").addClass("hidden");
      }
    }
  };

  controller.prototype.showVideo = function (obj) {
    var url=$(obj).data( "video" );
    $('.video_content').removeClass("hide");
    $('.video_ovelay').removeClass("hide");
    $('.video').attr('src', url)
  };

  controller.prototype.hideVideo = function () {
    $('.video_content').addClass("hide");
    $('.video_ovelay').addClass("hide");
    $('.video').attr('src', '')
  };

  app.controller("WpWebinars", controller);
})(jQuery, app);
