(function ($, app) {

  var self = {};

  $(window).on("load", function() {
    slickHandler();
  });

  function slickHandler () {
    self.$slicks = $(".slick-responsive");
    self.$slickMains = $(".slick-sync-main");
    self.$slickNavs = $(".slick-sync-nav");
    app.slicks = self.$slicks;
    app.slickMains = self.$slickMains;
    app.slickNavs = self.$slickNavs;
    self.defaultBreakPoint = 767;
    self.initializedSlickClass = "slick-initialized";
    init();
  };

  function init() {
    initSlicks();
    initSlickMain();
    initSlicksNav();
    var lazyResize = _.debounce($.proxy(checkForSlickState, self), 100);
    window.addEventListener('resize', lazyResize);
  };

  /*
   * initialize the slick plugin with the options specified.
  */
  function initSlicks() {
    self.$slicks.slick({
      mobileFirst : true,
      arrows: false,
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '30px',
      responsive: [{
        breakpoint : self.defaultBreakPoint,
        settings: "unslick"
      }]
    });
  };

  /*
   * initialize the slick that will be used in modal as main carousel with the options specified.
  */
  function initSlickMain() {
    self.$slickMains.map(function(idx, obj){
      var ref = $(obj).attr("data-ref");
      $(obj).slick({
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.slick-nav-'+ref
      });
    });
  };

  /*
   * initialize the slick that will be used in modal as nav carousel with the options specified.
  */
  function initSlicksNav() {
    self.$slickNavs.map(function(idx, obj){
      var ref = $(obj).attr("data-ref");
      $(obj).slick({
        arrows: false,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: true,
        focusOnSelect: true,
        asNavFor: '.slick-main-'+ref
      });
    });
  };

  /*
   * checks whenever or not the resolution is less than 768 and the slider is not initialized
  */
  function checkForSlickState() {
    var windowWidth= $(window).width();

    var isInitiliazed = self.$slicks.hasClass(self.initializedSlickClass);
    if(windowWidth < self.defaultBreakPoint && !isInitiliazed){
      initSlicks();
    }
    return;
  };

})(jQuery, app);
