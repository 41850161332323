(function ($, app) {
  var self = null;

  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.init();
  };

  controller.prototype.init = function () {
    self.tabs = $('a[data-toggle="tab"]');

    //Ensure all the slicks inside the tabs get their apropiate width
    self.tabs.on('shown.bs.tab', function(e){
      app.slicks.trigger("resize");
      app.slicks.map(function(idx, obj){
        obj.slick.setPosition();
      });
    });
  };

  app.controller("TourDetails", controller);
})(jQuery, app);
