(function ($, app) {
  var ajaxHandlers = function () {};

  ajaxHandlers.prototype.retrieveCards = function($container, $loadButton, term, all, page, type, viewMode, sort) {
    var $loader = $(".loader"),
        $cards  = $container.find(".cards"),
        data = {
          "term": term,
          "all": all,
          "page": page,
          "type": type,
          "viewMode": viewMode,
          "sort": sort
        };

    $container.toggleClass("loading");
    $.ajax({
      url: "/atravel/api/reload_cards",
      data: data
    }).done(function(data) {
      page++;
      $loadButton.attr("data-page", page);
      $.each(data.Result["nodes"], function(i, el) {
        $cards.append(el);
      });
      $container.toggleClass("loading");
      if(data.Result["next"] === "0") {
        $loadButton.addClass("pagination-end");
      }
    });
  };

  ajaxHandlers.prototype.retrieveCountry = function(id) {
    $(".country_info_container").addClass("hide");
    var data = {
      "id": id
    };
    $.ajax({
      url: "/atravel/api/retrieve_country",
      data: data
    }).done(function(data) {
      $(".country_info h2").text(data.Result[0].name);
      $(".country_info p").html(data.Result[0].text);
      $(".country_info img").attr("src", data.Result[0].img);
      $(".country_info a").attr("href", data.Result[0].path);
      if(data.Result[0].img!='http://'+window.location.host+'/' || data.Result[0].text!='') {
        $(".country_info_container").removeClass("hide");
      }
      if($(window).width()<768) {
        $('html,body').animate({
          scrollTop: $(".country_info").offset().top
        }, 1000);
      }
    });
  };

  ajaxHandlers.prototype.retrieveAllCountries = function(obj) {
    var data = {
      "obj": obj.countries
    };
    $.ajax({
      url: "/atravel/api/retrieve_all_countries",
      data: data
    }).done(function(data){
      obj.countries=data;
      obj.processPaths(obj.countries);
      obj.registerEvents();

      //Loading carousel mobile
      var countries = ["countries"];
      countries["countries"]=obj.countries
      $(".map_carousel").html(obj.detailsTemplateMobile(countries));
      $(".map_carousel").slick({
        mobileFirst : true,
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '30px',
        adaptiveHeight: true,
        responsive: [{
          breakpoint : self.defaultBreakPoint,
          settings: "unslick"
        }]
      });
    });
  };

  ajaxHandlers.prototype.getTourPrices = function(id, date, obj) {
    data = {
      "id": id,
      "date": date
    };
    $.ajax({
      url: "/atravel/api/get_tour_prices",
      data: data
    }).done(function(data) {
      obj.priceSingle=data.Result[0].single;
      obj.priceDouble=parseFloat(data.Result[0].twin);
      $(".price").text("$"+obj.priceDouble.toLocaleString('en-US', {minimumFractionDigits: 2}));
      obj.calculateAmount();
      if(data.Result[0].available) {
        $(".error_msj").slideUp();
      }
      else {
        $(".error_msj").slideDown();
      }
    });
  };

  ajaxHandlers.prototype.sendEmail= function(to, message, subject) {
    $.ajax({
      type: "POST",
      url: "https://mandrillapp.com/api/1.0/messages/send.json",
      data: {
        'key': 'clAItSNhNFCIrGj0LAyQyw',
        'message': {
        'from_email': 'no-replay@konradgroup.com',
        'from_name': 'African Travel',
        'subject': subject,
        'text': message,
        'to': [{
          'email': to,
          'name': 'African Travel Support',
          'type': 'to'
          }]
        }
      }
    }).done(function(data) {
      if(data[0].status=="rejected") {
        $(".error_msj").text("Error sending email");
        $(".error_msj").slideDown();
      }
      else {
        $(".error_msj").text("Your email was sent successfully");
        $(".error_msj").slideDown();
      }
    });
  };

  app.util("AjaxHandlers", {
    retrieveCards: ajaxHandlers.prototype.retrieveCards,
    retrieveCountry: ajaxHandlers.prototype.retrieveCountry,
    getTourPrices: ajaxHandlers.prototype.getTourPrices,
    sendEmail: ajaxHandlers.prototype.sendEmail,
    retrieveAllCountries: ajaxHandlers.prototype.retrieveAllCountries
  });

})(jQuery, app);
