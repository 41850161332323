(function ($, app){
  "use strict";

  var controller = function ($element) {
    var self = this;
    self.CLASS_DESC = "open"; // Class for desending order.
    self.$element = $element.find(".js-button-sort");
    self.generalFunctions = app.util("generalFunctions");
    self.init();
  };

  controller.prototype.init = function () {
    var self = this;
    self.$element.on("click", function () { self.onButtonClicked($(this)); });
    self.setButtonState();
  };

  controller.prototype.setButtonState = function () {
    var self = this;
    var $button = self.$element;
    var buttonKey = $button.data("key");
    if (buttonKey) {
      var paramKey = self.generalFunctions.getUrlParameter("sort_by");
      if (paramKey === buttonKey) {
        var sortOrder = self.generalFunctions.getUrlParameter("sort_order");
        (sortOrder === "DESC") ?
          $button.addClass(self.CLASS_DESC) : $button.removeClass(self.CLASS_DESC);
      }
    }
  };

  controller.prototype.onButtonClicked = function ($element) {
    var self = this;
    var url = location.href;
    var sortBy = $element.data("key");
    var sortOrder = ($element.hasClass(self.CLASS_DESC)) ? "ASC" : "DESC";
    if (!sortBy) {
      throw new Exception("No data-key attribute set on the element");
    } else {
      url = self.generalFunctions.updateURLParam(url, "sort_by", sortBy);
      url = self.generalFunctions.updateURLParam(url, "sort_order", sortOrder);
      location.href = url;
    }
  };
  app.controller("ButtonSort", controller);
})(jQuery, app);
