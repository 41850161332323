(function ($, app) {
  var self = null;
  var AjaxHandlers;
  var menuMobileReady;
  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.menuMobileReady=false;
    self.init();
  };
  controller.prototype.init = function () {

  };

  controller.prototype.listeners = function () {

  };

  app.controller("WpStaticContent", controller);
})(jQuery, app);
