(function ($, app) {
  var self = null;
  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.init();
  };
  controller.prototype.init = function () {
    if($(".side-nav .menu li").length) {
      self.createDropDownMobileMenu(".side-nav .menu li", ".wp-hero");
    }

    // Create mobile dropdown menu for the search pages too
    if($(".search-tabs .menu-options li").length) {
      self.createDropDownMobileMenu(".search-tabs .menu-options li", ".search-tabs .tabs-mobile");
    }
    self.updateTopHeaderMobile();
    self.callAction();
    self.setActiveMenuDesktop();
    self.updateCoBrandForm();
    self.initializeFormValidations();
  };

  /*
   * Creates all mobile DropDown Menus according to the selector
   * @container: The selector for the container to append the menu
   */
  controller.prototype.createDropDownMobileMenu = function(selector, container) {
    var list='';
    $(selector).each(function(){
       list=list+'<li>' +
                    '<a tabindex="0" href="'+$(this).find('a').attr("href")+'" data-tokens="null" class="'+$(this).attr("class")+'">' +
                      '<span class="text">'+$(this).find('a').text()+'</span>' +
                    '</a>' +
                  '</li>';
    });
    var menu='<div class="tab-selector visible-xs"><div class="droptab-selector" data-controller="Droptab"><div class="btn-group bootstrap-select"><button type="button" class="btn dropdown-toggle btn-default" data-toggle="dropdown" title="All">' +
            '<span class="filter-option pull-left">Navigate to a page</span>&nbsp;<span class="bs-caret"><span class="caret"></span></span>' +
          '</button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu">'+list+'</ul></div></div></div></div>';
    $(container).append(menu);
  };

  // Uptading top header in admin emviromnet
  controller.prototype.updateTopHeaderMobile = function () {
    if($("#toolbar").length){
      $("#header_mobile .top_menu_mobile").css('top', "60px");
      $("#header_mobile .menu_mobile").css('top', "167px");
      $("#header_mobile .search_mobile").css('top', "182px");
    }
  };

  // Disable link to phone number in desktop resolutions
  controller.prototype.callAction = function () {
      // Define if browser is mobile
    var isMobile = {
      Android: function() {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };

    $('a[href^=tel]').each(function(){
      $(this).on('click', function(event){
        if( !isMobile.any() ){
         event.preventDefault();
        }
      });
    });
  };

  //Adding active state to desktop menu
  controller.prototype.setActiveMenuDesktop = function () {
    var url= window.location.href;
    var res = url.split("/");
    $("#header #main_menu >li a[href$='/"+res[3]+"'], #header #top-menu >li a[href$='/"+res[3]+"']").parent().addClass("active");
    $("#header .dropdown-menu a[href$='/"+res[3]+"']").parent().parent().parent().addClass("active");
  };

  // Update the form in the Co-Brand Partnership PageController
  // The submit button needs the class button-2 and also there's a text that needs to be displayed next to it
  controller.prototype.updateCoBrandForm = function () {
    var $coBrandForm = $(".co-brand-form");

    if($coBrandForm.length > 0) {
      var $submitDescription = $coBrandForm.find(".submit-description");
      $coBrandForm.find(".form-actions").append($submitDescription);
    }
  };

  // Initializing ParsleyJs validations
  controller.prototype.initializeFormValidations = function(){
    if($(".webform-client-form").length > 0){
      $('.webform-client-form').parsley();
      if($(".webform-client-form .email-to-confirm").length > 0){
        $(".webform-client-form .email-to-confirm").attr("data-parsley-equalto", ".email-first");
        $(".webform-client-form .email-first").attr("data-parsley-equalto", ".email-to-confirm");
      }
      if($(".webform-client-form #edit-submitted-logo-upload").length > 0){
        $(".webform-client-form #edit-submitted-logo-upload").attr("required", "required");
        $(".webform-client-form").submit(function(){
          if($("#edit-submitted-logo-upload-button").val()=='Upload'){
            if(!$("#error_upload").length){
              $("#edit-submitted-logo-upload").after('<ul id="error_upload" class="parsley-errors-list filled"><li>Please upload the file</li></ul>');
            }
            return false;
          }
        });
      }
    }
  };

  app.controller("PageController", controller);
})(jQuery, app);
