(function ($, app) {
  var self = null;

  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.init();
  };
  controller.prototype.init = function () {
    self.listeners();
  };

  controller.prototype.listeners = function () {
    $(".question").on("click", function(){
      if($(window).width()<768 ){
        if($(this).hasClass("active")){
          $(".answer").slideUp();
          $(this).removeClass("active");
        }else{
          $(".answer").slideUp();
          $(".question").removeClass("active");
          $(this).addClass("active");
          $(".active + .answer").slideDown();
        }
      }
    });

  };

  controller.prototype.showAnswer = function (obj) {
    $(obj).addClass("active");
  };
  controller.prototype.hideAnswers = function () {
    $(".question").removeClass("active");
  };

  app.controller("WpFaqs", controller);
})(jQuery, app);
