
(function ($, app) {
  var self = null;
  var AjaxHandlers;
  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.init();
  };
  controller.prototype.init = function () {
    self.listeners();
  };

  controller.prototype.listeners = function () {
    $("#header_mobile .menu_icon").on("click", function(){
      self.showMenu();
    });

    $("#header_mobile .icon_search").on("click", function(){
      self.showSearch();
    });

    $("#header_mobile .overlay").on("click", function(){
      self.hideElements();
    });

  };

  controller.prototype.showMenu = function () {
    $("#header_mobile .menu_mobile").removeClass("translate_menu_close");
    $("#header_mobile .menu_mobile").addClass("translate_menu_open");
    $("#header_mobile .overlay").addClass("displayOverlay");
    $("body").addClass("fixed_body");
  };

  controller.prototype.showSearch = function () {
    $("#header_mobile .overlay").addClass("displayOverlay");
    $("#header_mobile .search_mobile").removeClass("hidden");
    $("body").addClass("fixed_body");
  };

  controller.prototype.hideElements = function () {
    if($("#header_mobile .menu_mobile").hasClass('translate_menu_open')){
      $("#header_mobile .menu_mobile").scrollTop(0);
      $("#header_mobile .menu_mobile").removeClass("translate_menu_open");
      $("#header_mobile .menu_mobile").addClass("translate_menu_close");
    }else{
      $("#header_mobile .search_mobile").addClass("hidden");
    }
    $("#header_mobile .overlay").removeClass("displayOverlay");
    $("body").removeClass("fixed_body");
  };

  app.controller("HeaderMobile", controller);

})(jQuery, app);
