(function ($, app) {
  var self = null;

  var controller = function ($element) {
    self = this;
    self.$element = $element;
    self.track = $element.attr("data-track");
    FormFunctions = app.util("formFunctions");
    self.init();
  };

  controller.prototype.init = function () {
    FormFunctions.handleHiddenFields();
    FormFunctions.addClassBySelector('.form-actions .webform-submit','button-2');
    FormFunctions.addClassBySelector('.node-readmore','hidden');

    if(self.track === "track"){
      var $form = self.$element.closest("form");
      $form.submit(function(){
        if(typeof ga !== 'undefined'){
          ga('send', 'event', self.$element.attr("data-action"), self.$element.attr("data-label"), self.$element.attr("data-value"));
        }
      });
    }
  };

  app.controller("WebformController", controller);

})(jQuery, app);