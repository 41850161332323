(function ($, app) {
  var self = null;
  var component = function ($element) {
    self = this;
    self.$element = $element;
    self.init();
  };

  component.prototype.init = function () {
    var $selectpicker =  self.$element.find(".selectpicker");
    self.dropchange($selectpicker);
  };

  component.prototype.dropchange = function($el){
    $el.change(function(){
      var $optionSelected = $(".droptab-selector option:selected"),
          tabRef          = $optionSelected.attr("data-tab"),
          $tabTrigger     = $("a[href="+tabRef+"]")

      $tabTrigger.click();
    });
  };

  app.controller("Droptab", component);
})(jQuery, app);